import React, { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData, withArtDirection } from 'gatsby-plugin-image';
import { GetDefaultEventHeroQuery } from '../../gatsby-graphql'

export const DefaultEventHeroImage: React.FC = () => {
    const data: GetDefaultEventHeroQuery = useStaticQuery(graphql`
        query getDefaultEventHero {
            file(
                relativePath: {eq: "settings.md"}
                sourceInstanceName: {eq: "site-settings"}
            ) {
                childMarkdownRemark {
                frontmatter {
                    eventHero {
                        altText
                        heroImage {
                            childImageSharp {
                            ...HeroImage
                            }
                            
                        }
                    }
                }
                }
            }
        }
    `)
    const hero = data?.file?.childMarkdownRemark?.frontmatter?.eventHero
    const heroImageData = hero?.heroImage?.childImageSharp

    const images = useMemo(() => {
        if (!heroImageData) return
        return withArtDirection(getImage(heroImageData!.smallImage) as IGatsbyImageData, [
            {
                media: "(min-width: 75em)",
                image: getImage(heroImageData!.largeImage) as IGatsbyImageData
            },
            {
                media: "(min-width: 30em)",
                image: getImage(heroImageData!.mediumImage!) as IGatsbyImageData
            },
        ])
    }, [])

    return (
        <div className='DefaultHeroImage'>
            <GatsbyImage
                image={images!}
                alt={hero?.altText || ''}
                style={{ width: "100%" }}
                loading="eager" />
        </div>
    );
}