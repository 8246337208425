import React, { useMemo } from "react"
import { Hero } from "components/Hero"
import { HeroImageType } from "components/HeroImage"
import { Layout } from "components/Layout"
import { Seo } from "components/Seo"
import { graphql, PageProps } from "gatsby"
import { GatsbyImage, getImage, IGatsbyImageData, withArtDirection } from "gatsby-plugin-image"
import { EventPageQuery, MarkdownRemarkFrontmatter, Maybe } from '../../gatsby-graphql'
import { DefaultEventHeroImage } from "components/DefaultEventHeroImage"
import { PageFiles } from "components/PageFiles"

export type DataProps = EventPageQuery

const EventPage: React.FC<PageProps<DataProps>> = ({ data }) => {
    const { markdownRemark } = data
    const { frontmatter, html } = markdownRemark || { frontmatter: null, html: null }
    const heroImageData = frontmatter?.hero?.heroImage?.childImageSharp as Maybe<HeroImageType>
    const pageFiles = frontmatter?.pageFiles

    const images = useMemo(() => {
        if (!heroImageData) return
        return withArtDirection(getImage(heroImageData!.smallImage) as IGatsbyImageData, [
            {
                media: "(min-width: 75em)",
                image: getImage(heroImageData!.largeImage) as IGatsbyImageData
            },
            {
                media: "(min-width: 30em)",
                image: getImage(heroImageData!.mediumImage!) as IGatsbyImageData
            },
        ])
    }, [])



    return (
        <Layout pageClass="EventPage">
            <Seo title={frontmatter?.title || ''}
                description={frontmatter?.metaDescription || ''} />
            <Hero
                title={frontmatter?.title || ''}
                date={frontmatter?.startDate}
                endDate={frontmatter?.endDate}
            >
                {heroImageData && images ?
                    <GatsbyImage
                        image={images!}
                        alt={frontmatter?.hero?.altText || ''}
                        style={{ width: "100%" }}
                        loading="eager" />
                    :
                    <DefaultEventHeroImage />
                }
            </Hero>
            <div className="inner">
                <div
                    className="EventPage-content"
                    dangerouslySetInnerHTML={{ __html: html || '' }}
                ></div>

            </div>
            <PageFiles files={pageFiles} />

        </Layout >
    )
}
export default EventPage

export const pageQuery = graphql`
    query EventPage($uri: String!) {
        markdownRemark(frontmatter: { path: { eq: $uri } }) {
            html
            frontmatter {
                title
                metaDescription
                startDate
                endDate
                location
                path
                hero {
                    altText
                    heroImage {
                        childImageSharp {
                            ...HeroImage
                        }
                    }
                }
                pageFiles {
                    fileName
                    pageFile {
                        publicURL
                    }
                }
            }
        }
    }
`